.header-distance {
  margin-top: 100px;
}

.header-distance-l {
  margin-top: 155px;
}

@media (width >= 768px) {
  .header-distance-l {
    margin-top: 0;
  }
}

/* @clearing */

/* -------------------------------------------- */

.clearfix::after {
  visibility: hidden;
  display: block;
  content: ' ';
  clear: both;
}

.clear {
  clear: both;
}
