@import '../../variables.scss';

html {
  font-size: 14px;
}

@media (width >= 768px) {
  html {
    font-size: 16px;
  }
}

.full-width {
  width: 100%;
}

.overflow-scroll {
  overflow: scroll;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 450px;
  min-height: 50px;
  opacity: 0.7;
}

.aligner-item {
  max-width: 50%;
  color: black;
  font-weight: bold;
  font-size: x-large;
}

.aligner-item-top {
  align-self: flex-start;
}

.aligner-item-bottom {
  align-self: flex-end;
}

.beatabg {
  background: url('../../resources/images/cita-previa.webp') no-repeat left 0;
}

.scrollable-wrapper {
  position: relative;
  max-width: 500px;
  margin: 1em auto;
  border: 1px solid black;
  overflow: hidden hidden;
}

.scrollable {
  background: #f3f3f3;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: auto hidden;
  -webkit-overflow-scrolling: touch;
  margin: 0 3em;

  .scrollable-item {
    display: inline-block;
    width: 100px;
    height: 100%;
    outline: 1px dotted gray;
    padding: 0.5em 1em;
    box-sizing: border-box;
    color: rgb(67 113 50);
    background-color: rgb(232 240 229);
    box-shadow: rgb(67 113 50) 0 0 0 1px inset;

    &.active {
      box-shadow: none;
      color: rgb(255 255 255);
      background-color: rgb(88 148 66);
    }
  }
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  border: 0;
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
}

.left-paddle {
  left: 0;
}

.right-paddle {
  right: 0;
}

.hidden {
  display: none;
}

.print {
  margin: auto;
  max-width: 500px;

  span {
    display: inline-block;
    width: 100px;
  }
}

.field-label {
  font-size: 0.8125rem;
  font-weight: 400;
  font-family: RalewayX, arial, sans-serif;
  font-style: normal;
  line-height: 1.3846;
}

.field-margin {
  margin: 0 3em;
}

.submit-form-button,
.submit-form-button:hover {
  background: $uceme-green;
  border-color: $uceme-green;
}

.hospital-name {
  display: inline-block;
  width: 9em;
  height: 100%;
  padding: 0.5em 1em;
  box-sizing: border-box;
  color: rgb(67 113 50);
  background-color: rgb(232 240 229);
  box-shadow: rgb(67 113 50) 0 0 0 1px inset;

  &.active {
    box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
    box-sizing: border-box;
    color: rgb(255 255 255);
    background-color: rgb(88 148 66);
  }
}

@media (width >= 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}
