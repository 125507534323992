@import 'https://fonts.cdnfonts.com/css/diablo';

body {
  margin: 0;
  font-family:
    Calibri,
    Candara,
    Segoe,
    'Segoe UI',
    Optima,
    Arial,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body-invert {
  font-family:
    Diablo,
    Calibri,
    Candara,
    Segoe,
    'Segoe UI',
    Optima,
    Arial,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  filter: invert(1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
