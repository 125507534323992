@import '../variables.scss';

html {
  font-size: 14px;
}

@media (width >= 768px) {
  html {
    font-size: 16px;
  }
}

@media (width <= 767px) {
  .hidden-xs {
    display: none !important;
  }
}

a.navbar-brand {
  white-space: normal;
}

.navbar-div-container {
  z-index: 100;
  vertical-align: middle;
}

.site-title-div {
  display: table-cell;
  top: 100px;
  transition: all 0.3s;
}

.site-title-div-small {
  top: 50px !important;
  transition: all 0.3s;
}
