@import '../../variables.scss';

.padding-top {
  padding-top: 1.5em;
}

.next-dates-modal {
  min-width: 37em;
}

.clickable {
  cursor: pointer;
}
