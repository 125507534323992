@import '../variables.scss';

html {
  font-size: 14px;
}

@media (width >= 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
}

.uceme-header {
  color: white;
  position: fixed;
  background-color: $uceme-green;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid #eee;
  transition: height 0.3s;
}

a.big-logo {
  display: block;
  width: 130px;
  float: left;
  background-size: contain;
  transition: all 0.3s;
}

a.small-logo {
  display: block;
  width: 80px !important;
  float: left;
  background-size: contain;
  transition: all 0.3s;
}

.site-title-div {
  display: table-cell;
  vertical-align: middle;
  color: white;
  height: 100px;
  transition: all 0.3s;
}

.site-title-div-small {
  vertical-align: middle;
  color: white;
  height: 50px !important;
  transition: all 0.3s;
}

.site-title {
  font-size: 1.3em;
  margin: 0;
  margin-left: 10px;
  background: none;
  color: #fff;
  font-weight: bold;
  float: right;
}

@media (width <= 767px) {
  .hidden-xs {
    display: none !important;
  }
}

.logo-container {
  padding-left: 30px;
}

.fixed-button {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
  cursor: pointer;
}

.rounded-fixed-btn {
  height: 2em;
  width: 9em;
  font-size: 1.6em;
  font-weight: bold;
  border-radius: 1em;
  background-color: white;
  border: 1px solid $uceme-orange;
  color: $uceme-green;
  text-align: center;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
}

@media (width <= 768px) {
  .rounded-fixed-btn > span {
    line-height: 1.7em;
  }
}

.appointment-icon {
  width: 30px;
  height: 30px;
  margin: 5px 5px 5px 10px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0 / 70%);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgb(0 0 0 / 80%);
}
