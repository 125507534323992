$uceme-green: #0e888e;
$uceme-green-safe: #099;
$uceme-nexgen-green: #3a9686;
$uceme-legacy-green: #72beb1;
$uceme-orange: #d6700c;
$uceme-gray: #3e4851;
$uceme-text: #444;
$navbar-padding-x: 0.5em;

.navbar {
  --bs-navbar-padding-x: 0.5rem;
}
