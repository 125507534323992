@import '../../variables.scss';

.carousel {
  width: 100%;
  max-height: 840px;
}

.carousel-item {
  max-height: 740px;

  img {
    width: 100%;
    height: 100%;
  }
}

.carousel-caption {
  color: black;
  text-shadow: 0 1px 0 rgb(255 255 255 / 40%);
  background: white;
  width: 100%;
  text-align: center;
  right: 0%;
  left: 0%;
}

.carousel-indicators li {
  background-color: $uceme-green;
}
